/**
 * Returns a display label for the current locale.
 * The locale must contain a language and a region (e.g. "en-US").
 *
 * @param locale The locale to use.
 * @param logger The logger to use.
 */
export function getCountryLanguageLabel(
  locale?: string | undefined | null,
  logger = console
) {
  if (!locale) {
    return '';
  }

  let language = 'en';
  let country: string | undefined;
  if (!locale.includes('-')) {
    // assume only a country is given
    country = locale;
  } else {
    [language = 'en', country] = locale.split('-');
  }

  if (!country) {
    return '';
  }

  try {
    const languageName = new Intl.DisplayNames([language], {
      type: 'language'
    });
    const regionName = new Intl.DisplayNames([language], {
      type: 'region'
    });

    return `${regionName.of(country)} - ${languageName.of(language)}`;
  } catch (e) {
    logger.warn('could not get current country label.', locale, e);
  }

  return '';
}
